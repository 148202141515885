<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  components: {
    highcharts: Chart
  },

  props: {
    chartOptions: {
      type: Object
    }
  }
};
</script>

<style lang="scss"></style>
