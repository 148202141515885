<template>
  <v-card>
    <v-card-title
      >{{ title }} nos anos de {{ filters.ano_ref - 2 }} /
      {{ filters.ano_ref - 1 }} / {{ filters.ano_ref }}</v-card-title
    >
    <v-card-text>
      <Chart :chartOptions="chartOptions" />
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "@/components/shared/BaseChartTeste";
import campanha from "@/services/http/campanhaService";

export default {
  props: {
    title: String,
    accumulator: String,
    filters: {}
  },
  components: {
    Chart
  },
  data() {
    return {
      years: [],
      chartOptions: {
        chart: {
          type: "column",
          height: 388
        },
        colors: ["#d46d26", "#1D3B5F", "#4AAC45"],
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        xAxis: {
          categories: ["Valores em R$"]
        },
        yAxis: {
          title: {
            text: this.title
          }
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>R$ {point.y},00</b></td></tr>',
          footerFormat: "</table>",
          shared: false,
          useHTML: true
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                let formattedValue = this.y
                  .toString()
                  .split("")
                  .reverse()
                  .join("")
                  .replace(/\d{3}(?=\d)/g, "$&.")
                  .split("")
                  .reverse()
                  .join("");

                return `R$ ${formattedValue},00`;
              }
            }
          }
        },
        series: [],
        lang: {
          noData: "Não há dados para serem apresentados"
        },
        noData: {
          style: {
            fontWeight: "normal",
            fontSize: "1rem",
            color: "rgba(0, 0, 0, 0.6)"
          }
        }
      }
    };
  },

  watch: {
    filters(value) {
      this.fetchApuracaoCalculo(value);
    }
  },

  methods: {
    async fetchApuracaoCalculo(filter) {
      this.years = [filter.ano_ref - 2, filter.ano_ref - 1, filter.ano_ref];
      this.chartOptions.series = [];
      for (let year of this.years) {
        if (year > 2020) {
          let {
            data: { data }
          } = await campanha()
            .resultadoRealizadoEmpresa()
            .show({
              per_page: -1,
              ano_ref: year,
              id_band: filter.id_band,
              id_empresa: filter.id_empresa
            });
          const obj = this.groupByCompany(data, "ano_ref");
          this.getSeriesCategories(year, Object.values(obj));
        }
      }
    },
    getSeriesCategories(year, data) {
      let resultado = data.map(item => {
        return item.resultado;
      });

      this.chartOptions.series.push({
        name: year,
        data: resultado
      });
    },

    groupByCompany(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || {
          apelido: currentValue.apelido,
          resultado: 0
        }).resultado = result[currentValue[key]].resultado += parseInt(
          currentValue[this.accumulator]
        );
        return result;
      }, {});
    }
  },

  mounted() {
    if (this.filters.ano_ref == undefined) {
      const date = new Date().toISOString().substr(0, 7);
      const [year] = date.split("-");
      this.fetchApuracaoCalculo(year);
    } else {
      this.fetchApuracaoCalculo(this.filters);
    }
  }
};
</script>

<style></style>
